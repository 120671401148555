import {defineStore} from 'pinia'

let language = navigator.language || navigator.userLanguage;
let lang = 'en';
if (language) {
    if (language === 'zh-TW' || language === 'zh-HK') {
        lang = "zhHant";
    } else if (/^zh/.test(language.toLowerCase())) {
        lang = "zh";
    } else {
        lang = "en";
    }
}
export const useLanguageStore = defineStore('language', {
    state: () => ({
        currentLang: lang // 默认英文
    }),

    actions: {
        setLanguage(lang) {
            this.currentLang = lang
        }
    }
})

export const modeStore = defineStore('darkMode', {
    state: () => ({
        currentMode: "light" // 默认英文
    }),

    actions: {
        setMode(mode) {
            this.currentMode = mode
        }
    }
})