import './assets/global.scss'; // 导入全局样式文件
import {createApp} from 'vue'
import {createPinia} from 'pinia'
import App from './App.vue'
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
const vuetify = createVuetify({
    components,
    directives,
})
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
const app = createApp(App)
const pinia = createPinia()
app.use(pinia)
app.use(vuetify)
app.mount('#app')
