<template>
  <!-- 联系方式区块容器 -->
  <article class="contact-section">
    <!-- 联系信息消息容器 -->
    <div class="contact-message">
      <div>
        <!-- 联系信息文本 -->
        <div class="contact-message-text">
          {{ currentLanguageConfig.coontactSection.content1 }}
        </div>
        <!-- 联系邮箱地址 -->
        <div class="contact-email">
          {{ currentLanguageConfig.coontactSection.content2 }}
        </div>
      </div>
    </div>
  </article>
</template>

<script>
// 定义联系方式区块组件
import {useLanguageStore} from "@/stores/store";
import {computed} from "vue";
import locales from "@/locales";

export default {
  name: "ContactSection",
  setup() {
    const languageStore = useLanguageStore()
    const currentLanguageConfig = computed(() => {
      return locales[languageStore.currentLang]
    })

    return {
      currentLanguageConfig
    }
  }
}
</script>

<style scoped lang="scss">
// 联系方式区块样式
.contact-section {
  padding: 4.25rem 0.625rem; // 100px 10px
  display: flex;
  flex-direction: row;
  gap: 0.625rem; // 10px
  align-items: center;
  justify-content: center;
  //position: absolute;  // 已注释掉的绝对定位
  //left: 405px;
  //top: 4702px;
  @media only screen and (max-width: 48rem) {
    padding: 2rem 2rem;
  }
}

// 联系信息消息样式
.contact-message {
  color: #000000;
  text-align: center;
  position: relative;

  // 联系信息文本样式
  &-text {
    color: var( --slogan-title-color);
    font-family: "Roboto-Regular", sans-serif;
    //color: #000000;
    //font-family: "Inter-Regular", sans-serif;
    font-size: 1rem; // 16px
    font-weight: 400;
  }
}

// 联系邮箱地址样式
.contact-email {
  color: var(--slogan-title-color);
  font-family: "Roboto-Regular", sans-serif;
  //color: #000000;
  //font-family: "Inter-Regular", sans-serif;
  font-size: 1rem; // 16px
  font-weight: 400;
  padding-top: 0.5rem;
}
</style>