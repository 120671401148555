export default {
    en: {
        navHeader: {
            language: 'English',
            brandName: 'MagicGourd'
        },
        coreFeatures: {
            title1: "Annotate Anything, build your second brain in a simpler way",
            // title2: "in one place",
            description1: "I'm your all-in-one reading and annotation assistant!",
            description2: "At just 1 year old, I'm still just a browser extension.",
            description3: "What should I become in the future? Log into the extension and share your ideas with my creator!",
            add1: "Add to Chrome",
            add2: "Add to Edge",
            add3: "Add to Firefox",
            add4: "Add to Safari",
            comming: "coming soon ...",
            webPageName: "Webpage",
            webPageDetail: "Highlight and annotate any webpage effortlessly to save key information.",
            pdfName: "PDFs",
            pdfPageDetail: "Mark up PDFs with text or crop image to keep your documents organized.",
            youtubeName: "YouTube",
            youtubeDetail: "Tag specific moments on YouTube videos to capture insights like never before.",
            bilibiliName: "Bilibili",
            bilibiliDetail: "Highlight your favorite moments to keep track of the valuable knowledge."
        },
        featureShowCase: {
            webTitle: "Highlight text or images directly on any web page.",
            webDescription: "Effortlessly highlight text or image on any web page using MagicGourd. Enhance your highlights with notes and tags for better organization and accessibility.",
            pdfTitle: "Easily highlight any online PDF right in your browser.",
            pdfDescription: "With MagicGourd, you can easily highlight text in PDFs or create image highlights by cropping any area, making it especially useful for images, tables, formulas, and more within PDFs.",
            videoTitle: "Tag specific moments on YouTube and Bilibili videos to capture insights.",
            videoDescription: "Never miss a key moment—easily capture valuable insights from your\n" +
                "          favorite videos and revisit them anytime with just a single click.",
            crossTitle: "Work seamlessly across desktop, tablet, and mobile.",
            crossDescription: "Experience the flexibility of MagicGourd on any device—whether you're\n" +
                "          using a desktop, tablet, or mobile. Highlight, annotate, and organize\n" +
                "          your content effortlessly, anytime and anywhere."
        },
        moreFeature: {
            name: "More Features",
            featureList: [
                {
                    title: "Highlight Images",
                    description: "Highlight images on webpages, PDFs, and videos.",
                    image: "panorama.webp"
                },
                {
                    title: "Take Notes",
                    description: "Add notes to any highlight or content, including webpages, PDFs, and videos.",
                    image: "chat.webp"
                },
                {
                    title: "Organize with Tags",
                    description: "Easily categorize and retrieve content by adding custom tags to highlights or pages.",
                    image: "sell.webp"
                },
                {
                    title: "Quick Search",
                    description: "Find content fast with keywords, filters, and sorting for pages or highlights.",
                    image: "search.webp"
                },
                {
                    title: "Unread List",
                    description: "Keep track of unread files, notes, and highlights in one place for easy access.",
                    image: "mark-email-unread.webp"
                },
                {
                    title: "Export to Markdown",
                    description: "Convert and export your highlights and notes to Markdown files with a single click.",
                    image: "ios-share.webp"
                },
                {
                    title: "Quoteshots",
                    description: "Transform your highlights into stunning quote images, ready to share.",
                    image: "format-quote.webp"
                },
                {
                    title: "End-to-End Encryption",
                    description: "Secure your notes with end-to-end encryption, ensuring your data stays private.",
                    image: "lock.webp"
                },
                {
                    title: "Offline Mode",
                    description: "You can make annotations offline once the page is fully loaded.",
                    image: "wifi_off.webp"
                },
                {
                    title: "Dark Mode",
                    description: "Automatically adapts to your system’s dark mode for a better viewing experience.",
                    image: "dark-mode.webp"
                },
                {
                    title: "Multi-Language",
                    description: "Use the interface in your preferred language with seamless multilingual support.",
                    image: "language.webp"
                },
                {
                    title: "Cross-Device Sync",
                    description: "Access and update your data seamlessly across all your devices.",
                    image: "cloud-done.webp"
                }
            ]
        },
        testimonialSection: {
            name: "Loved by users worldwide",
            subtitle: "MagicGourd is used and loved by users worldwide. People from bestseller\n" +
                "        authors, researchers, and students use MagicGourd to enhance their\n" +
                "        research and learning.",
            testimonials: [
                {
                    content: "MagicGourd has completely transformed the way I organize my ideas. Highlighting and note-taking have never been this intuitive and seamless. It’s like having a personal assistant for all my research projects!",
                    avatar: require("@/assets/images/image1.png"),
                    authorName: "Olivia Harper",
                    authorRole: "Writer"
                },
                {
                    content: "I use MagicGourd every day to highlight and capture key moments from videos. The video frame highlighting feature is incredibly intuitive, allowing me to tag and organize important insights effortlessly. It’s the perfect tool for staying productive while working with video content.",
                    avatar: require("@/assets/images/image2.png"),
                    authorName: "Liam Turner",
                    authorRole: "Tech Entrepreneur"
                },
                {
                    content: "MagicGourd has completely transformed the way I work with text. The ability to highlight and organize key information directly within documents makes reviewing and referencing so much easier. It’s an essential tool for staying focused and productive.”",
                    avatar: require("@/assets/images/image3.png"),
                    authorName: "Sophia Brooks",
                    authorRole: "Educator"
                },
                {
                    content: "I love how MagicGourd makes highlighting and collecting images so effortless. It’s a fantastic way to gather visual inspiration and organize ideas, helping me stay creative and focused while working on multiple design projects.",
                    avatar: require("@/assets/images/image4.png"),
                    authorName: "Ava Johnson",
                    authorRole: "Freelance Designer"
                },
                {
                    content: "With MagicGourd, I can finally keep all my research notes in one place. The Markdown export feature is a lifesaver for preparing presentations and sharing insights with my team.",
                    avatar: require("@/assets/images/image5.png"),
                    authorName: "Ethan Walker",
                    authorRole: "Data Scientist"
                },
                {
                    content: "MagicGourd lets me curate and share insights effortlessly. The quote-shot feature is a brilliant addition—it makes sharing ideas visually appealing and impactful!",
                    avatar: require("@/assets/images/image6.png"),
                    authorName: "Mason Bennett",
                    authorRole: "Marketing Specialist"
                },
                {
                    content: "MagicGourd is a game-changer for working with PDFs. Its seamless text and image highlighting, combined with the ability to add notes and tags, makes organizing and revisiting important content effortless. It has significantly improved my research workflow and saved me countless hours.",
                    avatar: require("@/assets/images/image7.png"),
                    authorName: "Emma Reed",
                    authorRole: "PhD Student"
                },
                {
                    content: "I can’t imagine managing my learning process without MagicGourd anymore. The interface is clean, the features are robust, and the cross-device sync works like a charm.",
                    avatar: require("@/assets/images/image8.png"),
                    authorName: "Noah Carter",
                    authorRole: "Startup Founder"
                },
                {
                    content: "MagicGourd keeps all my notes from articles, travel guides, and videos in one place. It’s the ultimate productivity tool for anyone who’s constantly on the go!",
                    avatar: require("@/assets/images/image9.png"),
                    authorName: "Isabella Wright",
                    authorRole: "Travel Blogger"
                }
            ]
        },
        coontactSection: {
            content1: "Our product is rapidly improving. If you have any ideas, feel free to contact us at: ",
            content2: "magic.gourd.app@gmail.com "
        },
        footer: {
            downloadTitle: "Download",
            chromeExtension: "Chrome Extension",
            chromeUrl: "https://chromewebstore.google.com/detail/cgnjokgoiepncjbgbjnihcpekgimnmaf",
            edgeExtension: "Edge Extension",
            edgeUrl: "https://microsoftedge.microsoft.com/addons/detail/kmoaicpbmfhbocapfopeakkcaoneebhm",
            firefoxExtension: "Firefox Extension",
            firefoxUrl: "https://addons.mozilla.org/firefox/addon/magic-gourd/",
            safariExtension: "Safari Extension (coming soon...)",
            safariUrl: "",
            documentTitle: "Document",
            helpCenter: "Help Center",
            helpUrl: "https://www.yuque.com/yuqueyonghu4j1trj/magic-gourd/gu6qpetgyzvbyocm",
            termsOfService: "Terms of Service",
            termsOfServiceUrl: "https://www.yuque.com/yuqueyonghu4j1trj/magic-gourd/mqzag3ovd3vl34b9",
            privacyPolicy: "Privacy Policy",
            privacyPolicyUrl: "https://www.yuque.com/yuqueyonghu4j1trj/magic-gourd/dd3o6dtupcnuxher",
        }
    },
    zh: {
        navHeader: {
            language: '中文简体',
            brandName: '魔法葫芦'
        },
        coreFeatures: {
            title1: "批注万物，用更简单的方式构建第二大脑",
            // title2: "魔法自然显现",
            description1: "我是你的阅读批注全能助手！",
            description2: "刚满 1 周岁的我，还只是一个浏览器插件。",
            description3: "未来我能为你做什么？登录插件，把你的想法告诉我的创造者吧！",
            add1: "Add to Chrome",
            add2: "Add to Edge",
            add3: "Add to Firefox",
            add4: "Add to Safari",
            comming: "正在上架...",
            webPageName: "网页",
            webPageDetail: "高亮和标注任意网页，一键标记文字、图片等关键信息，便于日后查找。",
            pdfName: "PDF",
            pdfPageDetail: "在浏览器中打开 PDF 并高亮文字，支持拉框标注图片、公式、表格等复杂内容。",
            youtubeName: "YouTube",
            youtubeDetail: "为 YouTube 视频的任意时刻添加标签，随时记录触动的画面，留下观看印记。",
            bilibiliName: "哔哩哔哩",
            bilibiliDetail: "在哔哩哔哩视频中随时标记高亮帧，通过时间轴的水滴标记快速复习关键内容。"
        },
        featureShowCase: {
            webTitle: "浏览器变阅读器，批注笔记随时随地。",
            webDescription: "轻松在任意网页原文上高亮文字（或图片），无论是重要段落还是关键信息都能一键标注。还可以为高亮内容添加笔记和标签，帮助你更高效地组织和分类，让信息查阅更加便捷无忧。",
            pdfTitle: "在线批注 PDF 并收集重点，研究人员的好帮手。",
            pdfDescription: "使用魔法葫芦，您可以轻松高亮 PDF 中的文本，或通过裁剪任意区域创建图片高亮，这对 PDF 中的图片、表格、公式等非常有用。",
            videoTitle: "观看视频，捕捉洞见，拒绝走马观花。",
            videoDescription: "为 YouTube 和哔哩哔哩视频的特定时刻添加标签，精准捕捉关键帧画面。不再错过任何精彩瞬间——轻松记录宝贵信息，并可随时一键回顾。",
            crossTitle: "任意浏览器，任意设备，纵享丝滑。",
            crossDescription: "魔法葫芦适配了不同大小的屏幕，无论是桌面、平板还是手机，都能轻松高亮、标注和整理内容，让你随时随地高效管理信息。"
        },
        moreFeature: {
            name: "更多特性",
            featureList: [
                {
                    title: "图片高亮",
                    description: "在任意网页或 PDF 上添加图片高亮；在 YouTube 或 Bilibili 视频添加高亮画面帧。",
                    image: "panorama.webp"
                },
                {
                    title: "记录笔记",
                    description: "对任意高亮或内容（网页、PDF、视频）添加笔记或备注，随时记录灵感。",
                    image: "chat.webp"
                },
                {
                    title: "添加标签",
                    description: "对任意高亮或内容（网页、PDF、视频）添加标签，分类整理让知识井井有条。",
                    image: "sell.webp"
                },
                {
                    title: "快捷搜索",
                    description: "支持所有历史记录进行关键词搜索，并且可以设置多种过滤条件以及排序逻辑。",
                    image: "search.webp"
                },
                {
                    title: "未读列表",
                    description: "尚未读完的内容，担心错过，可以标记为未读，从而可以在未读列表快速查看。",
                    image: "mark-email-unread.webp"
                },
                {
                    title: "导出 Markdown",
                    description: "支持一键将笔记连同高亮内容、网页信息导出为 Markdown 格式，便于其他笔记软件粘贴。",
                    image: "ios-share.webp"
                },
                {
                    title: "引用卡片",
                    description: "将任意高亮内容导出为精美的分享卡片，分享到社交平台，或者收藏到个人相册。",
                    image: "format-quote.webp"
                },
                {
                    title: "端到端加密",
                    description: "所有笔记、高亮、历史记录信息均采用了端到端加密技术，确保隐私安全。",
                    image: "lock.webp"
                },
                {
                    title: "离线模式",
                    description: "一旦网页内容完成加载，即使设备临时离线，也可以继续批注，上线后自动同步数据。",
                    image: "wifi_off.webp"
                },
                {
                    title: "深色模式",
                    description: "软件界面适配了深色模式和浅色模式，自动更随系统，让阅读过程更舒适。",
                    image: "dark-mode.webp"
                },
                {
                    title: "多语言",
                    description: "已经完成中文简体、中文繁体、英文的适配，并自动更随系统语言。",
                    image: "language.webp"
                },
                {
                    title: "跨设备数据同步",
                    description: "当使用多台设备时，设备之间的数据会自动保持同步。",
                    image: "cloud-done.webp"
                }
            ]
        },
        testimonialSection: {
            name: "广受用户好评",
            subtitle: "魔法葫芦受到来自全球用户的喜爱。无论是畅销书作家、研究人员，还是学生，都在使用魔法葫芦来提升他们的研究和学习效率。",
            testimonials: [
                {
                    content: "MagicGourd has completely transformed the way I organize my ideas. Highlighting and note-taking have never been this intuitive and seamless. It’s like having a personal assistant for all my research projects!",
                    avatar: require("@/assets/images/image1.png"),
                    authorName: "Olivia Harper",
                    authorRole: "Writer"
                },
                {
                    content: "I use MagicGourd every day to highlight and capture key moments from videos. The video frame highlighting feature is incredibly intuitive, allowing me to tag and organize important insights effortlessly. It’s the perfect tool for staying productive while working with video content.",
                    avatar: require("@/assets/images/image2.png"),
                    authorName: "Liam Turner",
                    authorRole: "Tech Entrepreneur"
                },
                {
                    content: "MagicGourd has completely transformed the way I work with text. The ability to highlight and organize key information directly within documents makes reviewing and referencing so much easier. It’s an essential tool for staying focused and productive.”",
                    avatar: require("@/assets/images/image3.png"),
                    authorName: "Sophia Brooks",
                    authorRole: "Educator"
                },
                {
                    content: "I love how MagicGourd makes highlighting and collecting images so effortless. It’s a fantastic way to gather visual inspiration and organize ideas, helping me stay creative and focused while working on multiple design projects.",
                    avatar: require("@/assets/images/image4.png"),
                    authorName: "Ava Johnson",
                    authorRole: "Freelance Designer"
                },
                {
                    content: "With MagicGourd, I can finally keep all my research notes in one place. The Markdown export feature is a lifesaver for preparing presentations and sharing insights with my team.",
                    avatar: require("@/assets/images/image5.png"),
                    authorName: "Ethan Walker",
                    authorRole: "Data Scientist"
                },
                {
                    content: "MagicGourd lets me curate and share insights effortlessly. The quote-shot feature is a brilliant addition—it makes sharing ideas visually appealing and impactful!",
                    avatar: require("@/assets/images/image6.png"),
                    authorName: "Mason Bennett",
                    authorRole: "Marketing Specialist"
                },
                {
                    content: "MagicGourd is a game-changer for working with PDFs. Its seamless text and image highlighting, combined with the ability to add notes and tags, makes organizing and revisiting important content effortless. It has significantly improved my research workflow and saved me countless hours.",
                    avatar: require("@/assets/images/image7.png"),
                    authorName: "Emma Reed",
                    authorRole: "PhD Student"
                },
                {
                    content: "I can’t imagine managing my learning process without MagicGourd anymore. The interface is clean, the features are robust, and the cross-device sync works like a charm.",
                    avatar: require("@/assets/images/image8.png"),
                    authorName: "Noah Carter",
                    authorRole: "Startup Founder"
                },
                {
                    content: "MagicGourd keeps all my notes from articles, travel guides, and videos in one place. It’s the ultimate productivity tool for anyone who’s constantly on the go!",
                    avatar: require("@/assets/images/image9.png"),
                    authorName: "Isabella Wright",
                    authorRole: "Travel Blogger"
                }
            ]
        },
        coontactSection: {
            content1: "我们的产品正在快速迭代，我们非常乐意听取您的想法！",
            content2: "欢迎通过 magic.gourd.app@gmail.com 与我们联系。"
        },
        footer: {
            downloadTitle: "Download",
            chromeExtension: "Chrome Extension",
            chromeUrl: "https://chromewebstore.google.com/detail/cgnjokgoiepncjbgbjnihcpekgimnmaf",
            edgeExtension: "Edge Extension",
            edgeUrl: "https://microsoftedge.microsoft.com/addons/detail/kmoaicpbmfhbocapfopeakkcaoneebhm",
            firefoxExtension: "Firefox Extension",
            firefoxUrl: "https://addons.mozilla.org/firefox/addon/magic-gourd/",
            safariExtension: "Safari Extension (soming soon...)",
            safariUrl: "",
            documentTitle: "Document",
            helpCenter: "Help Center",
            helpUrl: "https://www.yuque.com/yuqueyonghu4j1trj/magic-gourd/gu6qpetgyzvbyocm",
            termsOfService: "Terms of Service",
            termsOfServiceUrl: "https://www.yuque.com/yuqueyonghu4j1trj/magic-gourd/mqzag3ovd3vl34b9",
            privacyPolicy: "Privacy Policy",
            privacyPolicyUrl: "https://www.yuque.com/yuqueyonghu4j1trj/magic-gourd/dd3o6dtupcnuxher",
        }
    },
    zhHant: {
        navHeader: {
            language: '繁體中文',
            brandName: '魔法葫蘆'
        },
        coreFeatures: {
            title1: "批註萬物，以更簡單的方式構建第二大腦",
            // title2: "魔法自然顯現",
            description1: "我是你的閱讀批註全能助手！。",
            description2: "剛滿 1 周歲的我，還只是一個瀏覽器插件。",
            description3: "未來我能為你做什麼？登入插件，把你的想法告訴我的創造者吧！",
            add1: "Add to Chrome",
            add2: "Add to Edge",
            add3: "Add to Firefox",
            add4: "Add to Safari",
            comming: "正在上架...",
            webPageName: "網頁",
            webPageDetail: "高亮和標註任意網頁，一鍵標記文字、圖片等關鍵信息，便於日後查找。",
            pdfName: "PDF",
            pdfPageDetail: "在瀏覽器中打開 PDF 並高亮文字，支持拉框標註圖片、公式、表格等複雜內容。",
            youtubeName: "YouTube",
            youtubeDetail: "為 YouTube 視頻的任意時刻添加標籤，隨時記錄觸動的畫面，留下觀看印記。",
            bilibiliName: "嗶哩嗶哩",
            bilibiliDetail: "在嗶哩嗶哩視頻中隨時標記高亮幀，通過時間軸的水滴標記快速復習關鍵內容。"
        },
        featureShowCase: {
            webTitle: "瀏覽器變閱讀器，批註筆記隨時隨地。",
            webDescription: "輕鬆在任意網頁原文上高亮文字（或圖片），無論是重要段落還是關鍵信息都能一鍵標註。還可以為高亮內容添加筆記和標籤，幫助你更高效地組織和分類，讓信息查閱更加便捷無憂。",
            pdfTitle: "在線批註 PDF 並收集重點，研究人員的好幫手。",
            pdfDescription: "使用魔法葫蘆，您可以輕鬆高亮 PDF 中的文本，或通過裁剪任意區域創建圖片高亮，這對 PDF 中的圖片、表格、公式等非常有用。",
            videoTitle: "觀看視頻時，隨手捕捉關鍵畫面，回看更高效。",
            videoDescription: "為 YouTube 和嗶哩嗶哩視頻的特定時刻添加標籤，精準捕捉關鍵幀畫面。不再錯過任何精彩瞬間——輕鬆記錄寶貴信息，並可隨時一鍵回顧。",
            crossTitle: "任意瀏覽器，任意設備，盡在掌握。",
            crossDescription: "魔法葫蘆適配了不同大小的屏幕，無論是桌面、平板還是手機，都能輕鬆高亮、標註和整理內容，讓你隨時隨地高效管理信息。"
        },
        moreFeature: {
            name: "更多特性",
            featureList: [
                {
                    title: "圖片高亮",
                    description: "在任意網頁或 PDF 上添加圖片高亮；在 YouTube 或 Bilibili 視頻添加高亮畫面幀。",
                    image: "panorama.webp"
                },
                {
                    title: "記錄筆記",
                    description: "對任意高亮或內容（網頁、PDF、視頻）添加筆記或備註，隨時記錄靈感。",
                    image: "chat.webp"
                },
                {
                    title: "添加標籤",
                    description: "對任意高亮或內容（網頁、PDF、視頻）添加標籤，分類整理讓知識井井有條。",
                    image: "sell.webp"
                },
                {
                    title: "快捷搜索",
                    description: "支持所有歷史記錄進行關鍵詞搜索，並且可以設置多種過濾條件以及排序邏輯。",
                    image: "search.webp"
                },
                {
                    title: "未讀列表",
                    description: "尚未讀完的內容，擔心錯過，可以標記為未讀，從而可以在未讀列表快速查看。",
                    image: "mark-email-unread.webp"
                },
                {
                    title: "導出 Markdown",
                    description: "支持一鍵將筆記連同高亮內容、網頁信息導出為 Markdown 格式，便於其他筆記軟件粘貼。",
                    image: "ios-share.webp"
                },
                {
                    title: "引用卡片",
                    description: "將任意高亮內容導出為精美的分享卡片，分享到社交平台，或者收藏到個人相冊。",
                    image: "format-quote.webp"
                },
                {
                    title: "端到端加密",
                    description: "所有筆記、高亮、歷史記錄信息均採用了端到端加密技術，確保隱私安全。",
                    image: "lock.webp"
                },
                {
                    title: "離線模式",
                    description: "一旦網頁內容完成加載，即使設備臨時離線，也可以繼續批註，上線後自動同步數據。",
                    image: "wifi_off.webp"
                },
                {
                    title: "深色模式",
                    description: "軟件界面適配了深色模式和淺色模式，自動更隨系統，讓閱讀過程更舒適。",
                    image: "dark-mode.webp"
                },
                {
                    title: "多語言",
                    description: "已經完成中文簡體、中文繁體、英文的適配，並自動更隨系統語言。",
                    image: "language.webp"
                },
                {
                    title: "跨設備數據同步",
                    description: "當使用多台設備時，設備之間的數據會自動保持同步。",
                    image: "cloud-done.webp"
                }
            ]
        },
        testimonialSection: {
            name: "廣受用戶好評",
            subtitle: "魔法葫蘆受到來自全球用戶的喜愛。無論是暢銷書作家、研究人員，還是學生，都在使用魔法葫蘆來提升他們的研究和學習效率。",
            testimonials: [
                {
                    content: "MagicGourd has completely transformed the way I organize my ideas. Highlighting and note-taking have never been this intuitive and seamless. It’s like having a personal assistant for all my research projects!",
                    avatar: require("@/assets/images/image1.png"),
                    authorName: "Olivia Harper",
                    authorRole: "Writer"
                },
                {
                    content: "I use MagicGourd every day to highlight and capture key moments from videos. The video frame highlighting feature is incredibly intuitive, allowing me to tag and organize important insights effortlessly. It’s the perfect tool for staying productive while working with video content.",
                    avatar: require("@/assets/images/image2.png"),
                    authorName: "Liam Turner",
                    authorRole: "Tech Entrepreneur"
                },
                {
                    content: "MagicGourd has completely transformed the way I work with text. The ability to highlight and organize key information directly within documents makes reviewing and referencing so much easier. It’s an essential tool for staying focused and productive.”",
                    avatar: require("@/assets/images/image3.png"),
                    authorName: "Sophia Brooks",
                    authorRole: "Educator"
                },
                {
                    content: "I love how MagicGourd makes highlighting and collecting images so effortless. It’s a fantastic way to gather visual inspiration and organize ideas, helping me stay creative and focused while working on multiple design projects.",
                    avatar: require("@/assets/images/image4.png"),
                    authorName: "Ava Johnson",
                    authorRole: "Freelance Designer"
                },
                {
                    content: "With MagicGourd, I can finally keep all my research notes in one place. The Markdown export feature is a lifesaver for preparing presentations and sharing insights with my team.",
                    avatar: require("@/assets/images/image5.png"),
                    authorName: "Ethan Walker",
                    authorRole: "Data Scientist"
                },
                {
                    content: "MagicGourd lets me curate and share insights effortlessly. The quote-shot feature is a brilliant addition—it makes sharing ideas visually appealing and impactful!",
                    avatar: require("@/assets/images/image6.png"),
                    authorName: "Mason Bennett",
                    authorRole: "Marketing Specialist"
                },
                {
                    content: "MagicGourd is a game-changer for working with PDFs. Its seamless text and image highlighting, combined with the ability to add notes and tags, makes organizing and revisiting important content effortless. It has significantly improved my research workflow and saved me countless hours.",
                    avatar: require("@/assets/images/image7.png"),
                    authorName: "Emma Reed",
                    authorRole: "PhD Student"
                },
                {
                    content: "I can’t imagine managing my learning process without MagicGourd anymore. The interface is clean, the features are robust, and the cross-device sync works like a charm.",
                    avatar: require("@/assets/images/image8.png"),
                    authorName: "Noah Carter",
                    authorRole: "Startup Founder"
                },
                {
                    content: "MagicGourd keeps all my notes from articles, travel guides, and videos in one place. It’s the ultimate productivity tool for anyone who’s constantly on the go!",
                    avatar: require("@/assets/images/image9.png"),
                    authorName: "Isabella Wright",
                    authorRole: "Travel Blogger"
                }
            ]
        },
        coontactSection: {
            content1: "我們的產品正在快速迭代，我們非常樂意聽取您的想法！",
            content2: "歡迎通過 magic.gourd.app@gmail.com 與我們聯繫。"
        },
        footer: {
            downloadTitle: "Download",
            chromeExtension: "Chrome Extension",
            chromeUrl: "https://chromewebstore.google.com/detail/cgnjokgoiepncjbgbjnihcpekgimnmaf",
            edgeExtension: "Edge Extension",
            edgeUrl: "https://microsoftedge.microsoft.com/addons/detail/kmoaicpbmfhbocapfopeakkcaoneebhm",
            firefoxExtension: "Firefox Extension",
            firefoxUrl: "https://addons.mozilla.org/firefox/addon/magic-gourd/",
            safariExtension: "Safari Extension (soming soon...)",
            safariUrl: "",
            documentTitle: "Document",
            helpCenter: "Help Center",
            helpUrl: "https://www.yuque.com/yuqueyonghu4j1trj/magic-gourd/gu6qpetgyzvbyocm",
            termsOfService: "Terms of Service",
            termsOfServiceUrl: "https://www.yuque.com/yuqueyonghu4j1trj/magic-gourd/mqzag3ovd3vl34b9",
            privacyPolicy: "Privacy Policy",
            privacyPolicyUrl: "https://www.yuque.com/yuqueyonghu4j1trj/magic-gourd/dd3o6dtupcnuxher",
        }
    }
}