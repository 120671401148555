<template>
  <div id="app">
    <header>
      <NavHeader/>
    </header>
    <main aria-role="main" class="homepage">
      <div class="section-wrap">
        <section>
          <CoreFeatures/>
        </section>
        <section>
          <FeatureShowCase/>
        </section>
        <section>
          <MoreFeature/>
        </section>
        <section>
          <TestimonialSection/>
        </section>
        <section>
          <ContactSection/>
        </section>
      </div>
    </main>
    <footer class="footer wrapper">
      <WebFooter/>
    </footer>
  </div>
</template>

<script>
import NavHeader from './components/NavHeader.vue'
import CoreFeatures from './components/CoreFeatures.vue'
import FeatureShowCase from './components/FeatureShowCase.vue'
import MoreFeature from './components/MoreFeature.vue'
import TestimonialSection from './components/TestimonialSection.vue'
import ContactSection from './components/ContactSection.vue'
import WebFooter from './components/WebFooter.vue'
import {modeStore, useLanguageStore} from '@/stores/store'

import {setPageTitle} from "@/utils/utils";

export default {
  name: 'App',
  components: {
    NavHeader,
    CoreFeatures,
    FeatureShowCase,
    MoreFeature,
    TestimonialSection,
    ContactSection,
    WebFooter
  },
  computed: {
    currentLanguage() {
      return this.languageStore.currentLang;
    },
  },
  setup() {
    const languageStore = useLanguageStore()
    const currentModeStore = modeStore()
    const changeMode = (mode) => {
      currentModeStore.setMode(mode)
    }
    return {
      languageStore,
      changeMode
    }
  },
  mounted() {
    //设置当前页面title
    setPageTitle(this.currentLanguage);
    //根据操作系统的模式来切换当前模式
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');

    // 动态检测
    const handleDarkModeChange = (event) => {
      if (event.matches) {
        console.log("切换暗黑");
        this.changeMode("dark");
      } else {
        console.log("切换明亮");
        this.changeMode("light");
      }
    };
    // 监听变化
    darkModeQuery.addEventListener('change', handleDarkModeChange);

    // 初始检测
    if (darkModeQuery.matches) {
      this.changeMode("dark");
    } else {
      this.changeMode("light");
    }
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;

  .section-wrap {
    background: var(--section-background-color)
  }
}
</style>
