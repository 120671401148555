<template>
  <article class="testimonial-section">
    <div class="testimonial-header">
      <div class="testimonial-headline">
        <span>
          <span class="testimonial-headline-text">
            {{ currentLanguageConfig.testimonialSection.name }}
          </span>
          <span class="testimonial-headline-heart">❤️</span>
        </span>
      </div>
      <div class="testimonial-subtitle">
        {{ currentLanguageConfig.testimonialSection.subtitle }}
      </div>
    </div>
    <div class="testimonial-grid">
      <TestimonialCard
          v-for="testimonial in currentLanguageConfig.testimonialSection.testimonials"
          :key="testimonial.authorName"
          :content="testimonial.content"
          :avatar="testimonial.avatar"
          :author-name="testimonial.authorName"
          :author-role="testimonial.authorRole"
      />
    </div>
  </article>
</template>

<script>
import TestimonialCard from './TestimonialCard.vue'
import {computed} from 'vue'
import {useLanguageStore} from '@/stores/store'
import locales from '@/locales'


export default {
  name: "TestimonialSection",
  components: {
    TestimonialCard
  },
  setup() {
    const languageStore = useLanguageStore()
    const currentLanguageConfig = computed(() => {
      return locales[languageStore.currentLang]
    })

    return {
      currentLanguageConfig
    }
  }
}
</script>
<style scoped lang="scss">
.testimonial-section {
  padding: 4.25rem 0;
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  // 移动端适配
  @media only screen and (max-width: 48rem) {
    padding: 2rem 0;
    gap: 1.5rem;
  }
}

.testimonial-header {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: center;
  width: 42em;
  position: relative;
  @media only screen and (max-width: 48rem) {
    width: 20em;
  }
}

.testimonial-headline {
  color: var(--slogan-title-color);
  font-family: "Roboto-Regular",sans-serif;
  font-size: 1.75rem;
  text-align: center;
  //font-family: "Inter-Regular", sans-serif;
  //font-size: 2.5rem;
  font-weight: 400;
  position: relative;
  align-self: stretch;

  @media only screen and (max-width: 48rem) {
    font-size: 1.375rem;
  }

/*  &-text {
    color: #000000;
  }*/

  &-heart {
    color: #ff3647;
  }
}

.testimonial-subtitle {
  color: var(--slogan-description-color);
  font-family: "Roboto-Regular", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  //color: #4a5263;
  text-align: center;
  //font-family: "Inter-Regular", sans-serif;
  //font-size: 1rem;
  //font-weight: 400;
  position: relative;
  align-self: stretch;
  //word-break: keep-all;
  //height: 1.75em;
}

.testimonial-grid {
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  padding: 0 1.25rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18.75rem, 1fr));
  gap: 2rem;
  justify-items: center;

  @media only screen and (max-width: 48rem) {
    grid-template-columns: 1fr;
    max-width: 31.25rem;
  }
}
</style>
