<template>
  <section class="testimonial-card">
    <div class="testimonial-card-content">
      <span v-if="currentLang === 'zh'">
        “{{ content }}”
      </span>
      <span v-else>
        "{{ content }}"
      </span>
    </div>
    <div class="testimonial-author">
      <img
          v-if="avatar"
          class="testimonial-author-avatar"
          :src="avatar"
          :alt="authorName"
      />
      <div class="testimonial-author-info">
        <div class="testimonial-author-name">{{ authorName }}</div>
        <div class="testimonial-author-role">{{ authorRole }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import {useLanguageStore} from "@/stores/store";
import {computed} from "vue";

export default {
  name: "TestimonialCard",
  props: {
    content: {
      type: String,
      required: true
    },
    avatar: {
      type: String,
      required: true
    },
    authorName: {
      type: String,
      required: true
    },
    authorRole: {
      type: String,
      required: true
    }
  },
  setup() {
    const languageStore = useLanguageStore()
    const currentLang = computed(() => {
      return languageStore.currentLang;
    })

    return {
      currentLang
    }
  }
}
</script>

<style scoped lang="scss">
.testimonial-card {
  background: var(--show-case-card-background-color);
  border-radius: 0.625rem;
  box-shadow:
      0px 1px 3px 1px rgba(0, 0, 0, 0.15),
      0px 1px 2px 0px rgba(0, 0, 0, 0.3);

  max-width: 24rem;
  padding: 1.5rem;
/*  background: #FFFFFF;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;*/
  &-content {
    color: var(--slogan-description-color);
    font-family: "Roboto-Regular", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    //color: #000000;
    //font-family: "Inter-Regular", sans-serif;
    //font-size: 1rem;
    //font-weight: 400;
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  &-avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    object-fit: cover;
  }

  &-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &-name {
    color: var(--slogan-title-color);
    font-family: "Inter-Medium", sans-serif;
    font-size: 1rem;
    font-weight: 500;
  }

  &-role {
    color: var(--slogan-description-color);
    font-family: "Inter-Regular", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
  }
}
</style> 