/**
 * 判断当前设备是否为移动端
 * @returns {boolean} true表示是移动端,false表示非移动端
 */
export const isMobile = () => {
    // 方法1: 通过媒体查询判断
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    // 方法2: 通过userAgent判断
    const userAgent = navigator.userAgent.toLowerCase();
    const mobileKeywords = ['mobile', 'android', 'iphone', 'ipod', 'ipad', 'windows phone'];
    const isMobileUA = mobileKeywords.some(keyword => userAgent.includes(keyword));

    // 方法3: 检测触摸事件支持
    const hasTouch = 'ontouchstart' in window;

    // 综合判断:
    // 1. 屏幕宽度小于768px
    // 2. userAgent包含移动设备关键词
    // 3. 支持触摸事件
    return mediaQuery.matches && (isMobileUA || hasTouch);
}

export const setPageTitle = (langConfig) => {
    // 设置当前页面title
    if (langConfig === 'zh') {
        document.title = '魔法葫芦 - 批注万物';
    } else if (langConfig === 'zhHant') {
        document.title = '魔法葫蘆 - 批註萬物';
    } else {
        document.title = 'MagicGourd - Annotate anything';
    }
}
