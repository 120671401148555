<template>
  <article class="feature-showcase">
    <section class="feature-section pdf-highlight">
      <img class="feature-image" :src="require(`@/assets/images/page-text-highlight${currentMode === 'dark' ? '-dark' : ''}.webp`)" />
      <div class="feature-content">
        <div class="feature-title">
          {{ currentLanguageConfig.featureShowCase.webTitle }}
        </div>
        <div class="feature-description">
          {{ currentLanguageConfig.featureShowCase.webDescription }}
        </div>
      </div>
    </section>


    <section class="feature-section pdf-highlight" v-if="!isMobileDevice">
      <div class="feature-content">
        <div class="feature-title">
          {{ currentLanguageConfig.featureShowCase.pdfTitle }}
        </div>
        <div class="feature-description">
          {{ currentLanguageConfig.featureShowCase.pdfDescription }}
        </div>
      </div>
      <img class="feature-image" :src="require(`@/assets/images/pdf-highlight${currentMode === 'dark' ? '-dark' : ''}.webp`)" />
    </section>

    <section class="feature-section pdf-highlight" v-else>
      <img class="feature-image" :src="require(`@/assets/images/pdf-highlight${currentMode === 'dark' ? '-dark' : ''}.webp`)" />
      <div class="feature-content">
        <div class="feature-title">
          {{ currentLanguageConfig.featureShowCase.pdfTitle }}
        </div>
        <div class="feature-description">
          {{ currentLanguageConfig.featureShowCase.pdfDescription }}
        </div>
      </div>
    </section>

    <section class="feature-section video-highlight">
      <img v-if="!isMobileDevice" class="feature-image" :src="require(`@/assets/images/video-highlight${currentMode === 'dark' ? '-dark' : ''}.webp`)" />
      <div class="feature-content" v-if="!isMobileDevice">
        <div class="feature-title">
          {{ currentLanguageConfig.featureShowCase.videoTitle }}
        </div>
        <div class="feature-description">
          {{ currentLanguageConfig.featureShowCase.videoDescription }}
        </div>
      </div>



      <img v-if="isMobileDevice" class="feature-image" :src="require(`@/assets/images/video-highlight${currentMode === 'dark' ? '-dark' : ''}.webp`)" />
      <div class="feature-content" v-if="isMobileDevice">
        <div class="feature-title">
          {{ currentLanguageConfig.featureShowCase.videoTitle }}
        </div>
        <div class="feature-description">
          {{ currentLanguageConfig.featureShowCase.videoDescription }}
        </div>
      </div>

    </section>

    <section class="feature-section cross-platform" v-if="!isMobileDevice">
      <div class="feature-content">
        <div class="feature-title">
          {{ currentLanguageConfig.featureShowCase.crossTitle }}
        </div>
        <div class="feature-description">
          {{ currentLanguageConfig.featureShowCase.crossDescription }}
        </div>
      </div>
      <img class="feature-image" src="@/assets/images/cross-platform.webp" />
    </section>

    <section class="feature-section cross-platform" v-else>
      <img class="feature-image" src="@/assets/images/cross-platform.webp" />
      <div class="feature-content">
        <div class="feature-title">
          {{ currentLanguageConfig.featureShowCase.crossTitle }}
        </div>
        <div class="feature-description">
          {{ currentLanguageConfig.featureShowCase.crossDescription }}
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import {isMobile} from "@/utils/utils";
import {computed} from 'vue'
import {modeStore, useLanguageStore} from '@/stores/store'
import locales from '@/locales'
export default {
  name: "FeatureShowCase.vue",
  computed: {
    isMobileDevice() {
      return isMobile();
    },
    currentMode() {
      return this.currentModeStore.currentMode;
    }
  },
  data() {
    return {
      currentModeStore: modeStore()
    }
  },
  setup() {
    const languageStore = useLanguageStore()
    const currentLanguageConfig = computed(() => {
      return locales[languageStore.currentLang]
    })

    return {
      currentLanguageConfig
    }
  }
}

</script>



<style scoped lang="scss">
.feature-showcase {
  padding: 4.25rem 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  // 移动端适配
  @media only screen and (max-width: 48rem) {
    padding: 2rem 0;
    gap: 0;
  }
}

.feature-section {
  //width: 70em;
  padding: 1.5rem 2.5rem;
  display: flex;
  flex-direction: row;
  gap: 2.125rem;
  align-items: center;
  justify-content: center;
  // align-self: stretch;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 48rem) {
    flex-direction: column;
  }
}

.feature-image {
  flex-shrink: 0;
  /*  width: 35.375rem;
    height: 23.9375rem;*/
  width: 39.3vw;
  height: 26.5vw;

  position: relative;
  //object-fit: cover;
  object-fit: contain;

  @media only screen and (max-width: 48rem) {
    width: 22rem;
    height: 15rem;
  }
}

.feature-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  //width: 32rem;
  width: 37vw;
  position: relative;

  @media only screen and (max-width: 48rem) {
    width: 20rem;
    gap: 1.5rem;
  }
}

.feature-title {
  color: var(--show-case-title-color);
  font-family: "Roboto-Regular", sans-serif;
  font-size: 1.375rem;
  font-weight: 400;

  //color: #1d1b20;
  //font-family: "Roboto-Regular", sans-serif;
  //font-weight: 400;
  //color: #000000;
  text-align: left;
  //font-family: "Inter-SemiBold", sans-serif;
  //font-size: 1.75rem;
  //font-weight: 600;
  position: relative;
  //width: 32rem;
  //height: 2.9575rem;
  @media only screen and (max-width: 48rem) {
    width: 20rem;
    font-size: 1.5rem;
  }
}

.feature-description {
  color: var(--show-case-description-color);
  font-family: "Roboto-Regular", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  //color: #49454f;
  //font-family: "Roboto-Regular", sans-serif;
  letter-spacing: 0.5px;
  //color: #4a5263;
  text-align: left;
  //font-family: "Inter-Regular", sans-serif;
  //font-size: 1rem;
  //font-weight: 400;
  position: relative;
  align-self: stretch;
  //height: 3.31rem;
}

.feature-section.video-highlight {
  padding: 1.5rem 2.5rem;
  display: flex;
  flex-direction: row;
  gap: 2.125rem;
  align-items: center;
  justify-content: center;
  // align-self: stretch;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 48rem) {
    flex-direction: column;
  }
}

.feature-section.video-highlight .feature-image {
  flex-shrink: 0;
  /*  width: 35.375rem;
    height: 24.4375rem;*/
  width: 39.3vw;
  height: 27.2vw;
  position: relative;
  //object-fit: cover;
  object-fit: contain;
  @media only screen and (max-width: 48rem) {
    width: 22rem;
    height: 15rem;
  }
}

.feature-section.cross-platform {
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  gap: 2.2rem;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 48rem) {
    flex-direction: column;
  }
}

.feature-section.cross-platform .feature-image {
  flex-shrink: 0;
  /*  width: 35.375rem;
    height: 25.7275rem;*/
  width: 39.3vw;
  height: 28.5vw;
  position: relative;
  object-fit: cover;
  @media only screen and (max-width: 48rem) {
    width: 22rem;
    height: 15rem;
  }
}

.feature-section.cross-platform .feature-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 37vw;
  position: relative;
  @media only screen and (max-width: 48rem) {
    width: 20rem;
    gap: 1.5rem;
  }
}

.feature-section.cross-platform .feature-title {
  color: var(--show-case-title-color);
  font-family: "Roboto-Regular", sans-serif;
  font-size: 1.375rem;
  font-weight: 400;
  //color: #1d1b20;
  //font-family: "Roboto-Regular", sans-serif;
  //font-weight: 400;
  //color: #000000;
  text-align: left;
  //font-family: "Inter-SemiBold", sans-serif;
  //font-size: 1.75rem;
  //font-weight: 600;
  position: relative;
  //width: 32.0131rem;
  //height: 3.1881rem;
  @media only screen and (max-width: 48rem) {
    width: 20rem;
    font-size: 1.5rem;
  }
}

.feature-section.cross-platform .feature-description {
  color: var(--show-case-description-color);
  font-family: "Roboto-Regular", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  //color: #49454f;
  //font-family: "Roboto-Regular", sans-serif;
  letter-spacing: 0.5px;
  //color: #4a5263;
  text-align: left;
  //font-family: "Inter-Regular", sans-serif;
  //font-size: 1rem;
  //font-weight: 400;
  position: relative;
  align-self: stretch;
  //height: 3.5706rem;
}
</style>