<template>
  <div class="header">
    <div class="brand">
      <img class="logo" src="@/assets/images/logo-add-sparkle-remove-bg0.png"/>
      <div class="name">{{ currentLanguageConfig.navHeader.brandName }}</div>
    </div>
    <div class="store">
      <div class="container">
        <div class="state-layer">
          <img class="icon"
               :src="currentMode === 'dark' ? require(`@/assets/images/dark_mode.webp`) :require(`@/assets/images/light-mode.webp`)"
               @click="switchMode()"/>
        </div>
      </div>

      <v-select
          v-model="selectedLanguage"
          :items="languages"
          outlined
      >
        <!-- 左侧图标 -->
        <template v-slot:prepend-inner>
          <img
              class="icon"
              :src="currentMode === 'dark' ? require(`@/assets/images/locale-earth-dark.png`) : require(`@/assets/images/locale-earth.png`)"
              alt="icon"
          />
        </template>
      </v-select>

      <!--      <a-dropdown :trigger="['click']">
              <a class="ant-dropdown-link" @click.prevent>
                {{ currentLanguageConfig.navHeader.store }}
                <DownOutlined/>
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="changeLang('en')">
                    <a href="javascript:;">English</a>
                  </a-menu-item>
                  <a-menu-item @click="changeLang('zh')">
                    <a href="javascript:;">中文简体</a>
                  </a-menu-item>
                  <a-menu-item @click="changeLang('zhHant')">
                    <a href="javascript:;">繁體中文</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>-->
      <!--      <img class="dropdown" src="@/assets/images/down-1-10.png"/>-->
    </div>
  </div>
</template>

<script>
import {computed} from 'vue'
import {useLanguageStore} from '@/stores/store'
import {modeStore} from '@/stores/store'
import locales from '@/locales'
import {setPageTitle} from "@/utils/utils";

/*import {Dropdown} from 'ant-design-vue';
import {Menu} from 'ant-design-vue';
import {MenuItem} from 'ant-design-vue';
import {DownOutlined} from '@ant-design/icons-vue';*/

export default {
  name: "NavHeader",
  data() {
    return {
      selectedLanguage: '简体中文', // 默认选中繁體中文
    }
  },
  methods: {
    switchMode() {
      if (this.currentMode === 'dark') {
        //全局响应明亮模式
        this.changeMode("light");
      } else if (this.currentMode === 'light') {
        //全局响应黑暗模式
        this.changeMode("dark");
      }
    }
  },
  mounted() {
    /*setInterval(() => {
      function printDOMTree(element, indent = '') {
        if (!element) return;

        // 打印当前元素的标签名和属性
        if (element.nodeType === Node.ELEMENT_NODE) {
          let output = `${indent}<${element.tagName.toLowerCase()}`;

          // 打印属性
          for (const attr of element.attributes) {
            output += ` ${attr.name}="${attr.value}"`;
          }
          output += '>';

          console.log(output);
        }

        // 打印文本节点
        if (element.nodeType === Node.TEXT_NODE && element.textContent.trim()) {
          console.log(`${indent}${element.textContent.trim()}`);
        }

        // 打印注释节点
        if (element.nodeType === Node.COMMENT_NODE) {
          console.log(`${indent}<!--${element.textContent}-->`);
        }

        // 递归打印子节点
        for (const child of element.childNodes) {
          printDOMTree(child, indent + '  ');
        }

        // 打印闭合标签
        if (element.nodeType === Node.ELEMENT_NODE) {
          console.log(`${indent}</${element.tagName.toLowerCase()}>`);
        }
      }

// 获取目标元素
      const overlayContainer = document.querySelector('.v-overlay-container');

      if (overlayContainer) {
        printDOMTree(overlayContainer);
      } else {
        console.log('未找到 class 为 v-overlay-container 的元素');
      }
    },2000)*/
    if (this.currentLanguage === "zhHant") {
      this.selectedLanguage = '繁體中文';
    } else if (this.currentLanguage === "zh") {
      this.selectedLanguage = '简体中文';
    } else {
      this.selectedLanguage = 'English';
    }
  },

  computed: {
    currentMode() {
      return this.currentModeStore.currentMode;
    },
    currentLanguage() {
      return this.languageStore.currentLang;
    },
  },
  watch: {
    currentMode() {
      if (this.currentMode === 'dark') {
        //全局响应黑暗模式
        this.changeMode("dark");
        document.body.setAttribute('arco-theme', 'dark'); // 添加 dark 属性
      } else if (this.currentMode === 'light') {
        //全局响应明亮模式
        this.changeMode("light");
        document.body.removeAttribute('arco-theme'); // 移除 dark 属性
      }
    },
    // 监听 selectedLanguage 的变化
    selectedLanguage(newLang) {
      if (newLang === '繁體中文') {
        this.changeLang("zhHant");
      } else if (newLang === 'English') {
        this.changeLang("en");
      } else if (newLang === '简体中文') {
        this.changeLang("zh");
      }
    },
  },
  /*  components: {
      ADropdown: Dropdown,
      AMenu: Menu,
      AMenuItem: MenuItem,
      DownOutlined
    },*/
  setup() {
    const languageStore = useLanguageStore()
    const currentModeStore = modeStore()
    const languages = ['English', '简体中文', '繁體中文'];

    const currentLanguageConfig = computed(() => {
      return locales[languageStore.currentLang]
    })
    const changeMode = (mode) => {
      currentModeStore.setMode(mode)
    }
    // 切换语言
    const changeLang = (lang) => {
      languageStore.setLanguage(lang);
      //设置当前页面title
      setPageTitle(lang);
    }

    return {
      languageStore,
      currentModeStore,
      changeMode,
      languages,
      changeLang,
      currentLanguageConfig
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  margin-right: 0.4rem;
  background: transparent;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;

  &:hover, &:focus, &:active {
    background: var(--icon-wrap);
  }

  .state-layer {
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;

    .icon {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

:deep(*) {
  .ant-dropdown-menu-title-content {
    //font-size: 1.05rem;
    font-size: 0.875rem;
    @media only screen and (max-width: 48rem) {
      font-size: 0.8rem;
    }
  }

  .v-field__overlay {
    background-color: transparent !important;
  }

  .v-field.v-field--active.v-field--appended.v-field--center-affix.v-field--dirty.v-field--prepended.v-field--no-label.v-field--variant-filled.v-theme--light.v-locale--is-ltr {
    border-radius: 0.5rem;
    border-style: solid;
    border-color: #cac4d0;
    border-width: 1px;
    height: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.3rem;
  }

  .v-field__outline, .v-input__details {
    display: none;
  }

  .v-field__input {
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 0.875rem;
    min-height: auto;
    padding-right: 0;
  }

  .v-field__field {
    align-items: center;
  }

  .mdi-menu-down.mdi.v-icon.notranslate.v-theme--light.v-icon--size-default.v-select__menu-icon {
    margin-left: 0;
  }


}

.header {
  /*  border-style: solid;
    border-color: #eef0f3;
    border-width: 0 0 0.0625rem 0;*/
  padding: 1rem 7.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  //background-color: #ffffff;
  background-color: var(--header-color);
  overflow: hidden;
}

.brand {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 15.7rem;
  height: 2.56rem;
  position: relative;
}

.logo {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  object-fit: cover;
}

.name {
  color: var(--brand-name-color);
  font-family: "Roboto-Medium", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  //color: #333333;
  text-align: left;
  //font-family: "Inter-Bold", sans-serif;
  //font-size: 2.2rem;
  //font-weight: 700;
  position: relative;
}

.store {
  height: 2rem;
  color: var(--brand-name-color);
  font-family: "Roboto-Medium", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  margin-left: 1rem;

  //color: #000000;
  text-align: left;
  //font-family: "Inter-SemiBold", sans-serif;
  //font-size: 1.25rem;
  //font-weight: 600;

  .ant-dropdown-link {
    width: 6.7rem;
    @media only screen and (max-width: 48rem) {
      width: 5.3rem;
    }
  }
}

.icon {
  flex-shrink: 0;
  width: 1.125rem;
  height: 1.125rem;
  position: relative;
  object-fit: cover;
}

.text {
  color: #000000;
  text-align: left;
  font-family: "Inter-SemiBold", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  position: relative;
}

.dropdown {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
  object-fit: cover;
}

// 适配移动端屏幕
@media only screen and (max-width: 48rem) {
  .header {
    padding: 1rem;
  }

  .brand {
    width: auto;
  }

  .name {
    font-size: 1rem;
  }

  .text {
    font-size: 1rem;
  }
  .store {
    font-size: 1rem;
  }
  .logo {
    width: 1.35rem;
    height: 1.35rem;
  }

  .icon {
    width: 1.1rem;
    height: 1.1rem;
  }
}

.store-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #eef0f3;
  border-radius: 4px;
  margin-top: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.store-item {
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
}

.store {
  position: relative;
  cursor: pointer;
}
</style>