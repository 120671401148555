<template>
  <article class="more-features-container">
    <h1 class="more-features-title">{{ currentLanguageConfig.moreFeature.name }}🌟</h1>
    <div class="features-grid">
      <div class="feature-card" v-for="feature in currentLanguageConfig.moreFeature.featureList" :key="feature.title">
        <div class="feature-title">
          <img class="feature-icon"
               :src="currentMode === 'dark' ? require(`@/assets/images/${feature.image.split('.webp')[0] + '-dark.webp'}`) :require(`@/assets/images/${feature.image}`)"/>
          <span>{{ feature.title }}</span>
        </div>
        <div class="feature-description">
          {{ feature.description }}
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import {computed} from 'vue'
import {modeStore, useLanguageStore} from '@/stores/store'
import locales from '@/locales'

export default {
  name: "MoreFeature.vue",
  computed: {
    currentMode() {
      return this.currentModeStore.currentMode;
    },
  },
  setup() {
    const languageStore = useLanguageStore()
    const currentModeStore = modeStore()
    const currentLanguageConfig = computed(() => {
      return locales[languageStore.currentLang]
    })

    return {
      currentModeStore,
      currentLanguageConfig
    }
  }
}

</script>

<style scoped lang="scss">
.more-features-container {
  padding: 4.25rem 0;
  gap: 3.75rem;
  width: 100%;
  overflow: hidden;
  // 移动端适配
  @media only screen and (max-width: 48rem) {
    padding: 2rem 0;
  }
}

.more-features-title {
  color: var(--show-case-title-color);
  font-family: "Roboto-Regular", sans-serif;
  font-size: 1.75rem;
  //color: #000000;
  text-align: center;
  //font-family: "Inter-Regular", sans-serif;
  //font-size: 2.5rem;
  font-weight: 400;
  position: relative;
  align-self: stretch;
  padding-bottom: 4rem;
  @media only screen and (max-width: 48rem) {
    font-size: 1.5rem;
    padding-bottom: 2rem;
  }
}

.features-grid {
  /*display: flex;
  flex-direction: row;
  gap: 0.5625rem;
  row-gap: 1.25rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  flex-shrink: 0;
  position: relative;*/
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18.75rem, 1fr));
  gap: 1rem 0.5rem;
  justify-items: center;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  padding: 0 1.25rem;
}

.feature-card {
  background: var(--show-case-card-background-color);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15),
  0 1px 2px 0 rgba(0, 0, 0, 0.3);
  //background: #f3f4f6;
  border-radius: 0.625rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  //width: 25em;
  //height: 11em;
  position: relative;
  max-width: 24rem;
  @media only screen and (max-width: 48rem) {
    max-width: 20rem;
  }
}

.feature-icon {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  object-fit: cover;
}

.feature-title {
  display: flex;
  //justify-content: center;
  align-items: center;
  color: var(--slogan-title-color);
  font-family: "Roboto-Medium", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  //color: #000000;
  text-align: left;
  //font-family: "Inter-Regular", sans-serif;
  //font-size: 1.375rem;
  //font-weight: 400;
  position: relative;

  span {
    padding-left: 0.5rem;
  }
}

.feature-description {
  color: var(--slogan-description-color);
  font-family: "Roboto-Regular", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  //color: #5b5563;
  text-align: left;
  //font-family: "Inter-Regular", sans-serif;
  //font-size: 1rem;
  //font-weight: 400;
  position: relative;
}
</style>